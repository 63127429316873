import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CrmCtgDossiersService } from 'src/app/services/crm-ctg-dossiers.service';
import { UsersService } from 'src/app/services/users.service';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { DocumentService } from 'src/app/services/document.service';
import { Document, Folder } from 'backend/src/models/document-model';
import { ToastrService } from 'ngx-toastr';
import { HelpersService } from 'src/app/services/helpers.service';
import { Product } from 'src/app/utils/api';
import * as moment from 'moment';
import 'moment/locale/nl-be';
import * as filesize from 'filesize';
import { allowedExtensions } from 'backend/src/models/dossier-model';
import { HistoryService } from 'src/app/services/history.service';
moment.locale('nl-be');

@Component({
    selector: 'app-crm-ctg-dossier-detail',
    templateUrl: './crm-ctg-dossier-detail.component.html',
    styleUrls: ['./crm-ctg-dossier-detail.component.scss']
})
export class CrmCtgDossierDetailComponent implements OnInit, OnDestroy {
    @Input() id?: number;
    @Input() mode?: string;
    @Input() activePage?: string;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    dossier: any = null;
    documents: any = null;

    formSTR: any = {};

    ready: boolean = false;
    saving: boolean = false;
    activePopover: any = null;

    pages: { code: any; name: any; notify?: boolean; icon?: string }[];
    form: any;
    popoverHelper: any = null;
    validation: any = {};
    changes: boolean = false;
    helper: any = {};

    yesNoOptions;

    applicationTypes: any[];
    dossierManagers: any[] = [];
    experts: any[] = [];
    contractManagers: any[] = [];
    tableYears: any[] = [];

    //Stats resources
    marketingAuthOptions: any[];
    externalAppointedByApplicantOptions: any[];
    orphanDrugCollegeOptions: any[];
    opinionIntegratedD60D90Options: any[];
    externalCrmExportOptions: any[];
    etaCupMnpOptions: any[];

    //Budget resources
    phaseOptions: any[];
    budgetStatusOptions: any[];
    addedValueOptions: any[];
    finalSituationOptions: any[];
    reimbursementCategoryOptions: any[];
    hospitalRetailOptions: any[];
    adviceCrmOptions: any[];
    mohApprovalOptions: any[];
    finalClassOptions: any[];
    hearingOptions: any[];
    oncoProductOptions: any[];
    populationReimbursedOptions: any[] = [];
    populationSubmittedOptions: any[] = [];

    //Documents
    documentsReady: boolean = false;

    //History
    history: any = {};
    hisFolders: any = {};
    historyReady: boolean = false;
    historyTableHeads: any;

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private crmCtgDossiersService: CrmCtgDossiersService,
        private documentService: DocumentService,
        private historyService: HistoryService,
        private usersService: UsersService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private helpersService: HelpersService
    ) {}

    ngOnInit(): void {
        if (this.id) {
            this.getDossier();
        } else {
            this.dossier = {};
            this.getUsersFromProductCompany();
            this.initPages(false);
            this.resetForm();
            this.ready = true;
        }

        this.yesNoOptions = [
            { title: this.translatePipe.transform('crmCtgDossier_detail_yes'), value: true },
            { title: this.translatePipe.transform('crmCtgDossier_detail_no'), value: false }
        ];

        this.initActivePage(this.activePage, this.mode);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    initPages(notify) {
        this.pages = [
            {
                code: 'general',
                name: this.translatePipe.transform('crmCtgDossier_detail_menu_general'),
                icon: 'document'
            },
            { code: 'stats', name: this.translatePipe.transform('crmCtgDossier_detail_menu_stats'), icon: 'stats' },
            { code: 'budget', name: this.translatePipe.transform('crmCtgDossier_detail_menu_budget'), icon: 'budget' },
            {
                code: 'documents',
                name: this.translatePipe.transform('crmCtgDossier_detail_menu_documents'),
                icon: 'folder',
                notify: notify
            },
            {
                code: 'history',
                name: this.translatePipe.transform('crmCtgDossier_detail_menu_history'),
                icon: 'history'
            }
        ];
    }

    initActivePage(activePage, mode?) {
        switch (activePage) {
            case 'general':
                this.initGeneral(mode);
                break;
            case 'stats':
                this.initStats(mode);
                break;
            case 'budget':
                this.initBudget(mode);
                break;
            case 'documents':
                this.initDocuments();
                break;
            case 'history':
                this.initHistory();
                break;
            default:
                this.activePage = 'general';
                this.helpersService.addParam('activePage', this.activePage);
                this.initGeneral(mode);
                return;
        }
        this.helpersService.addParam('activePage', activePage);
    }

    initGeneral(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            if (this.id) {
                this.mode = 'show';
            } else {
                this.mode = 'create';
            }
        }

        this.activePage = 'general';
        this.initGeneralResources();
    }

    initGeneralResources() {
        this.applicationTypes = this.resourceService.getResourceByType('application_type');
    }

    initStats(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            this.mode = 'show';
        }
        this.activePage = 'stats';
        this.initStatResources();
    }

    initStatResources() {
        this.marketingAuthOptions = this.resourceService.getResourceByType('marketing_authorization');
        this.externalAppointedByApplicantOptions = this.resourceService.getResourceByType(
            'external_appointed_by_applicant'
        );
        this.orphanDrugCollegeOptions = this.resourceService.getResourceByType('orphan_drug_college');
        this.opinionIntegratedD60D90Options = this.resourceService.getResourceByType('opinion_integrated_d60_d90');
        this.externalCrmExportOptions = this.resourceService.getResourceByType('external_crm_expert');
        this.etaCupMnpOptions = this.InitYesNoOptions();
    }

    initBudget(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            this.mode = 'show';
        }
        this.activePage = 'budget';
        this.tableYears = [
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_initial_budget_enterprise'),
                code_year_1: 'init_budget_y1',
                code_year_2: 'init_budget_y2',
                code_year_3: 'init_budget_y3'
            },
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_actual_budget_riziv_inami'),
                code_year_1: 'actual_budget_y1',
                code_year_2: 'actual_budget_y2',
                code_year_3: 'actual_budget_y3'
            },
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_initial_incremental_budget_enterprise'),
                code_year_1: 'init_incremental_budget_y1',
                code_year_2: 'init_incremental_budget_y2',
                code_year_3: 'init_incremental_budget_y3'
            },
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_actual_incremental_budget_riziv_inami'),
                code_year_1: 'actual_incremental_budget_y1',
                code_year_2: 'actual_incremental_budget_y2',
                code_year_3: 'actual_incremental_budget_y3'
            },
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_initial_patients_enterprise'),
                code_year_1: 'init_patients_y1',
                code_year_2: 'init_patients_y2',
                code_year_3: 'init_patients_y3'
            },
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_actual_patients_riziv_inami'),
                code_year_1: 'actual_patients_y1',
                code_year_2: 'actual_patients_y2',
                code_year_3: 'actual_patients_y3'
            },
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_initial_cost_patient_year'),
                code_year_1: 'init_cpy_y1',
                code_year_2: 'init_cpy_y2',
                code_year_3: 'init_cpy_y3',
                type: 'float',
                readOnly: true
            },
            {
                label: this.translatePipe.transform('crmCtgDossier_detail_actual_cost_patient_year'),
                code_year_1: 'actual_cpy_y1',
                code_year_2: 'actual_cpy_y2',
                code_year_3: 'actual_cpy_y3',
                type: 'float',
                readOnly: true
            }
        ];
        this.initBudgetResources();
    }

    initBudgetResources() {
        this.phaseOptions = this.resourceService.getResourceByType('phase');
        this.budgetStatusOptions = this.resourceService.getResourceByType('budget_status');
        this.addedValueOptions = this.resourceService.getResourceByType('added_value');
        this.finalSituationOptions = this.resourceService.getResourceByType('final_situation');
        this.reimbursementCategoryOptions = this.resourceService.getResourceByType('reimbursement_category');
        this.hospitalRetailOptions = this.resourceService.getResourceByType('hospital_retail');
        this.adviceCrmOptions = this.resourceService.getResourceByType('advice_crm');
        this.mohApprovalOptions = this.resourceService.getResourceByType('moh_approval');
        this.finalClassOptions = this.resourceService.getResourceByType('final_class');
        this.hearingOptions = this.InitYesNoOptionsForSelect();
        this.oncoProductOptions = this.InitYesNoOptionsForSelect();
        this.populationReimbursedOptions = this.resourceService.getResourceByType('population_reimbursed');
        this.populationSubmittedOptions = this.resourceService.getResourceByType('population_submitted');
    }

    InitYesNoOptionsForSelect() {
        return [
            { value: 'Yes', key: true },
            { value: 'No', key: false }
        ];
    }

    InitYesNoOptions() {
        return [
            { title: 'Yes', value: true },
            { title: 'No', value: false }
        ];
    }

    initDocuments() {
        this.getDocuments();
    }

    initHistory() {
        this.historyTableHeads = [
            {
                name: this.translatePipe.transform('crmCtgDossiers_history_table_field'),
                code: 'field',
                width: '20%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_history_table_old_value'),
                code: 'before',
                width: '38%'
            },
            {
                name: '',
                code: 'icon',
                width: '4%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_history_table_new_value'),
                code: 'after',
                width: '38%'
            }
        ];
        this.getHistory();
    }

    initHisFolders() {
        for (const his of this.history) {
            this.hisFolders[his.timestamp] = { display: false };
        }
    }

    pageChange($event) {
        if (this.activePage === $event || !this.canExit()) return;
        this.changes = false;
        this.activePage = $event;
        this.initActivePage($event);
    }

    getActivePage() {
        if (this.activePage && this.pages) {
            return this.pages.find((item) => {
                return item.code == this.activePage;
            });
        }
    }

    getDocuments() {
        this.documentsReady = false;
        this.documentService.getDocuments(this.id).subscribe((next) => {
            this.documents = next;
            this.documentsReady = true;
        });
    }

    //

    getHistory() {
        this.historyReady = false;
        this.historyService.getHistory(this.id).subscribe((next) => {
            this.history = next;
            if (this.history && this.history.length > 0) {
                this.initHisFolders();
                for (const his of this.history) {
                    his.changes = this.setHistoryTableData(his.changes);
                }
            }
            this.historyReady = true;
        });
    }

    setHistoryTableData(changes) {
        if (changes) {
            return changes.map((change) => this.getHistoryTableData(change));
        } else {
            return [];
        }
    }

    getHistoryTableData(change) {
        const historyTableData = {
            ...change,
            icon: {
                type: 'icon',
                value: '>',
                class: 'td-new_value'
            },
            field: {
                type: 'field',
                page: this.translatePipe.transform(change.field + '_page'),
                label: this.translatePipe.transform(change.field)
            },
            before: {
                type: change.field.startsWith('comment') ? 'richtext' : 'text',
                value: change.before
            },
            after: {
                type: change.field.startsWith('comment') ? 'richtext' : 'text',
                value: change.after,
                class: 'td-new_value'
            }
        };
        return historyTableData;
    }

    openCloseHistoryItem(historyItem) {
        const elementId = historyItem.timestamp;
        var folder = this.hisFolders[elementId];
        var wrap = document.getElementById('history_' + elementId);
        var icon = document.getElementById('history_icon' + elementId);
        if (!folder.display) {
            icon.classList.remove('icon-up');
            icon.classList.add('icon-down');
            wrap.classList.remove('his-closed');
            wrap.classList.add('his-open');
            folder.display = true;
        } else {
            folder.display = false;
            icon.classList.remove('icon-down');
            icon.classList.add('icon-up');
            wrap.classList.remove('his-open');
            wrap.classList.add('his-closed');
        }
    }

    getDossier() {
        this.crmCtgDossiersService
            .getDossier(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (next) => {
                    this.dossier = next;
                    this.setInitCpy(this.dossier);
                    this.setActualCpy(this.dossier);
                    this.getUsersFromProductCompany(this.dossier.companies);
                    this.initPages(this.dossier.newDocument);
                    this.resetForm();
                    this.ready = true;
                },
                error: (error) => this.handleError(error)
            });
    }

    createUpdate() {
        const FORM = { ...this.formSTR };
        // return;
        this.saving = true;
        this.crmCtgDossiersService
            .updateInsertDossier(FORM, this.activePage, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    if (!this.id) {
                        this.id = next['id'];
                        this.dossier.id = this.id;
                        this.formSTR.id = this.id;
                        this.created.emit(this.formSTR);
                        var elements = document.getElementsByClassName('detail-modal--create');
                        while (elements.length > 0) {
                            elements[0].classList.remove('detail-modal--create');
                        }
                    } else {
                        this.updated.emit(this.formSTR);
                    }
                    this.changes = false;
                    this.getDossier();
                    this.mode = 'show';
                },
                error: (error) => this.handleError(error)
            });
    }

    setDossierNameFromProduct() {
        if (this.formSTR.products && this.formSTR.products.length > 0) {
            const product = this.formSTR.products[0].product;
            if (!this.formSTR.specialty || this.formSTR.specialty.length === 0) {
                this.formSTR.specialty = product.substring(
                    0,
                    this.indexOfFirstDigit(product) > 0 ? this.indexOfFirstDigit(product) : product.length
                );
            }
            if (!this.formSTR.name || this.formSTR.name.length === 0) {
                this.formSTR.name = product.substring(
                    0,
                    this.indexOfFirstDigit(product) > 0 ? this.indexOfFirstDigit(product) : product.length
                );
            }
            if (!this.formSTR.alias || this.formSTR.alias.length === 0) {
                this.formSTR.alias = product.substring(
                    0,
                    this.indexOfFirstDigit(product) > 0 ? this.indexOfFirstDigit(product) : product.length
                );
            }
        }
    }

    setProducts($event) {
        this.formSTR.products = $event;
        if (!this.formSTR?.products?.length) {
            this.formSTR.atc_chapter = null;
            this.formSTR.atc_code = null;
            this.formSTR.dci = null;
        }
    }

    setTableYear(code, $event) {
        this.formSTR[code] = $event;

        this.setInitCpy(this.formSTR);
        this.setActualCpy(this.formSTR);
    }

    setInitCpy(target) {
        if (this.isValidNumber(target['init_budget_y1'] / target['init_patients_y1'])) {
            target['init_cpy_y1'] = (target['init_budget_y1'] / target['init_patients_y1']).toFixed(2);
        }
        if (this.isValidNumber(target['init_budget_y2'] / target['init_patients_y2'])) {
            target['init_cpy_y2'] = (target['init_budget_y2'] / target['init_patients_y2']).toFixed(2);
        }
        if (this.isValidNumber(target['init_budget_y2'] / target['init_patients_y3'])) {
            target['init_cpy_y3'] = (target['init_budget_y3'] / target['init_patients_y3']).toFixed(2);
        }
    }

    setActualCpy(target) {
        if (this.isValidNumber(target['actual_budget_y1'] / target['actual_patients_y1'])) {
            target['actual_cpy_y1'] = (target['actual_budget_y1'] / target['actual_patients_y1']).toFixed(2);
        }
        if (this.isValidNumber(target['actual_budget_y2'] / target['actual_patients_y2'])) {
            target['actual_cpy_y2'] = (target['actual_budget_y2'] / target['actual_patients_y2']).toFixed(2);
        }
        if (this.isValidNumber(target['actual_budget_y2'] / target['actual_patients_y3'])) {
            target['actual_cpy_y3'] = (target['actual_budget_y3'] / target['actual_patients_y3']).toFixed(2);
        }
    }

    isValidNumber(number: number): boolean {
        return isFinite(number) && !isNaN(number);
    }

    indexOfFirstDigit(input) {
        let i = 0;
        for (; input[i] < '0' || input[i] > '9'; i++);
        return i == input.length ? -1 : i;
    }

    companyChange($event) {
        if ($event) {
            this.formSTR.companies = [];

            $event.forEach((item) => {
                if (item.company?.sam_id) {
                    if (!this.formSTR.companies.some((x) => x.lnk_sam_company_id === item.key)) {
                        this.formSTR.companies.push({
                            ...item,
                            lnk_sam_company_id: item.key
                        });
                    }
                } else {
                    if (!this.formSTR.companies.some((x) => x.lnk_company_id === item.key)) {
                        this.formSTR.companies.push({
                            ...item,
                            lnk_company_id: item.key
                        });
                    }
                }
            });

            this.getUsersFromProductCompany(this.formSTR.companies);
            this.formChange();
        }
    }

    productsChange($event) {
        if ($event?.length) {
            if (!this.formSTR.companies?.length) {
                this.formSTR.companies = [];
            }
            if ($event[0].company_id) {
                if (!this.formSTR.companies.some((x) => x.key === $event[0].company_id)) {
                    this.formSTR.companies.push({
                        company: { id: $event[0].samCompanyUuid, name: $event[0].companyName },
                        key: $event[0].company_id,
                        value: $event[0].companyName,
                        lnk_company_id: $event[0].company_id
                    });
                }
            } else if ($event[0]?.samCompanyUuid && !$event[0].company_id) {
                if (!this.formSTR.companies.some((x) => x.key === $event[0].samCompanyUuid)) {
                    this.formSTR.companies.push({
                        company: { sam_id: $event[0].samCompanyUuid, name: $event[0].companyName },
                        key: $event[0].samCompanyUuid,
                        value: $event[0].companyName,
                        lnk_sam_company_id: $event[0].samCompanyUuid
                    });
                }
            }
            this.getUsersFromProductCompany(this.formSTR.companies);
        }
    }

    getUsersFromProductCompany(companies?: { lnk_company_id?: number; lnk_sam_company_id?: string }[]) {
        if (!companies?.length) {
            this.clearUsers();
        }
        this.usersService
            .getUsers({
                startRow: 0,
                RPP: 99999,
                orderBy: null,
                dossierId: null,
                term: null,
                company_ids: companies?.filter((x) => x.lnk_company_id).map((x) => x.lnk_company_id) || [],
                sam_company_ids: companies?.filter((x) => x.lnk_sam_company_id).map((x) => x.lnk_sam_company_id) || []
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                this.experts = next.data
                    .filter((item) => {
                        return item.is_expert;
                    })
                    .map((item) => {
                        return {
                            value: item.firstname + ' ' + item.lastname,
                            key: item.id,
                            default: item.is_default_expert
                        };
                    });
                this.contractManagers = next.data
                    .filter((item) => {
                        return item.is_contract_manager;
                    })
                    .map((item) => {
                        return { value: item.firstname + ' ' + item.lastname, key: item.id };
                    });
                if (companies?.length) {
                    this.dossierManagers = next.data
                        .filter((item) => {
                            return item.code == 'user' || item.code == 'coordinator';
                        })
                        .map((item) => {
                            return { value: item.firstname + ' ' + item.lastname, key: item.id };
                        });
                }

                if (!this.id && (!this.formSTR.experts || this.formSTR.experts.length < 1)) {
                    const defaultExpert = this.experts.filter((item) => {
                        return item.default;
                    });

                    if (defaultExpert && defaultExpert.length > 0) {
                        this.addExpert(defaultExpert[0].key);
                    }
                }
            });
    }

    addExpert(key) {
        for (const expert of this.experts) {
            if (expert.key == key) {
                if (!this.formSTR.experts || this.formSTR.experts.length == 0) {
                    this.formSTR.experts = [{ key: expert.key, value: expert.value, is_primary: true }];
                } else {
                    this.formSTR.experts.push({ key: expert.key, value: expert.value });
                }
                break;
            }
        }
    }

    deleteExpert(index) {
        this.formSTR.experts.splice(index, 1);
    }

    setPrimaryExpert(key) {
        for (const expert of this.formSTR.experts) {
            if (expert.key == key) {
                expert.is_primary = true;
            } else {
                expert.is_primary = false;
            }
        }
    }

    getExpertsToExclude(model) {
        const optionsToExclude = [];
        if (model) {
            for (let i = 0; i < model.length; i++) {
                optionsToExclude.push(model[i].key);
            }
        }
        return optionsToExclude;
    }

    clearUsers() {
        this.formSTR.dossier_managers = [];
        this.dossierManagers = [];
    }

    getContractManager(id) {
        if (this.contractManagers) {
            const index = this.contractManagers.findIndex((item) => {
                return item.key == id;
            });

            if (index !== -1) {
                return this.contractManagers[index].value;
            }
        }
    }

    getProductInfo(product: Product) {
        return product.productPackage;
    }

    getProductNameFromProducts(products: Product[]) {
        if (products && products.length > 0) {
            return products[0].product;
        }
    }

    getAtcChapter() {
        if (this.formSTR?.products?.length) {
            return this.formSTR.products[0].atc_chapter;
        } else return this.formSTR.atc_chapter;
    }

    getAtcCode() {
        if (this.formSTR?.products?.length) {
            return this.formSTR.products[0].atc;
        } else return this.formSTR.atc_code;
    }

    getDCI() {
        if (this.formSTR?.products?.length) {
            return this.formSTR.products[0].dci;
        } else return this.formSTR.dci;
    }

    // getAtcChapter(dossier) {
    //     let atcChapter;
    //     if (dossier.atc_chapter) {
    //         atcChapter = dossier.atc_chapter;
    //     } else if (dossier.products && dossier.products.length > 0) {
    //         atcChapter = dossier.products[0].atc_chapter;
    //     }
    //     return atcChapter;
    // }

    // getAtcCode(dossier) {
    //     let atcCode;
    //     if (dossier.products && dossier.products.length > 0) {
    //         atcCode = dossier.products[0].atc;
    //     }
    //     if (!atcCode) {
    //         atcCode = dossier.atc_code;
    //     }
    //     return atcCode;
    // }

    // getDCI(dossier) {
    //     if (dossier.dci) {
    //         return dossier.dci;
    //     } else {
    //         if (dossier.products && dossier.products.length > 0) {
    //             return dossier.products[0].dci;
    //         }
    //     }
    // }

    getTitleDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return values.slice(0, 1).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return values.slice(1).join(', ');
        }
    }

    getStampDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return '+' + (values.length - 1);
        }
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = JSON.parse(JSON.stringify(this.dossier));
        if (this.dossier.company_id && this.dossier.company_name) {
            this.formSTR.initial_company_name = {
                key: this.dossier.company_id || this.dossier.lnk_sam_company_uuid,
                value: this.dossier.company_name,
                company: {
                    id: this.dossier.company_id || this.dossier.lnk_sam_company_uuid,
                    name: this.dossier.company_name
                }
            };
        }
        this.setInitCpy(this.formSTR);
        this.setActualCpy(this.formSTR);
    }

    cancelEdit() {
        if (this.canExit()) {
            this.mode = 'show';
            this.resetForm();
        }
    }

    canExit(): boolean {
        if (!this.changes || confirm(this.translatePipe.transform('exit'))) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    clickOnElement(elementId) {
        document.getElementById(elementId).click();
    }

    openConfirmAction(id) {
        document.getElementById(id).style.display = 'block';
    }

    closeConfirmAction(id) {
        document.getElementById(id).style.display = 'none';
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }

    handleError(error) {
        if (error.status == 500 || error.status == 403 || error.status == 404) {
            this.mode = 'globalError';
            this.changes = false;
        }
        this.validation = error.error.details;
    }
}
